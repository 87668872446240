import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import "./pricing-development.css";

const PricingDevelopment = (props) => {
  return (
    <div
      className={`pricing-development-pricing23 thq-section-padding ${props.rootClassName} `}
    >
      <div className="pricing-development-max-width thq-section-max-width">
        <div className="pricing-development-section-title">
          <div className="pricing-development-content">
            <h2 className="pricing-development-text thq-heading-2">
              {props.heading1}
            </h2>
            <p className="pricing-development-text01 thq-body-large">
              {props.subtitle1}
            </p>
          </div>
        </div>
        <div className="pricing-development-content1">
          <div className="pricing-development-container"></div>
          <div className="pricing-development-column1">
            <div className="pricing-development-price">
              <div className="pricing-development-price1">
                <p className="pricing-development-text02 thq-body-large">
                  {props.plan1}
                </p>
              </div>
              <h3 className="pricing-development-text03 thq-heading-3">
                {props.plan1Price}
              </h3>
            </div>
            <h3 className="pricing-development-text04 thq-heading-3">
              {props.plan2Price12}
            </h3>
          </div>
          <div className="pricing-development-column2">
            <div className="pricing-development-price2">
              <div className="pricing-development-price3">
                <p className="pricing-development-text05 thq-body-large">
                  {props.plan2}
                </p>
              </div>
              <h3 className="pricing-development-text06 thq-heading-3">
                {props.plan2Price}
              </h3>
            </div>
            <h3 className="pricing-development-text07 thq-heading-3">
              {props.plan2Price1}
            </h3>
          </div>
          <div className="pricing-development-column3">
            <div className="pricing-development-price4">
              <div className="pricing-development-price5">
                <p className="pricing-development-text08 thq-body-large">
                  {props.plan3}
                </p>
              </div>
              <h3>
                {props.text ?? (
                  <Fragment>
                    <h3 className="pricing-development-text43 thq-heading-3">
                      <span>$399</span>
                      <br></br>
                    </h3>
                  </Fragment>
                )}
              </h3>
            </div>
            <h3 className="pricing-development-text10 thq-heading-3">
              {props.plan2Price11}
            </h3>
          </div>
        </div>
        <div className="pricing-development-container01">
          <div className="pricing-development-content2">
            <h3 className="thq-heading-3">{props.featureCategory1}</h3>
          </div>
          <div className="pricing-development-container02">
            <div className="pricing-development-container03">
              <span className="thq-body-small">{props.feature1}</span>
            </div>
            <div className="pricing-development-container04">
              <span className="pricing-development-text13 thq-body-small">
                {props.count1}
              </span>
            </div>
            <div className="pricing-development-container05">
              <span className="thq-body-small">{props.count2}</span>
            </div>
            <div className="pricing-development-container06">
              <span className="pricing-development-text15 thq-body-small">
                {props.count3}
              </span>
            </div>
          </div>
          <div className="pricing-development-container07">
            <div className="pricing-development-container08">
              <span className="thq-body-small">{props.feature2}</span>
            </div>
            <div className="pricing-development-container09">
              <span className="thq-body-small">{props.feature21}</span>
            </div>
            <div className="pricing-development-container10">
              <span className="thq-body-small">{props.feature211}</span>
            </div>
            <div className="pricing-development-container11">
              <span className="pricing-development-text19 thq-body-small">
                {props.feature212}
              </span>
            </div>
          </div>
          <div className="pricing-development-container12">
            <div className="pricing-development-container13">
              <span className="thq-body-small">{props.feature3}</span>
            </div>
            <div className="pricing-development-container14">
              <span className="thq-body-small">{props.feature22}</span>
            </div>
            <div className="pricing-development-container15">
              <span className="thq-body-small">{props.feature213}</span>
            </div>
            <div className="pricing-development-container16">
              <span className="pricing-development-text23 thq-body-small">
                {props.feature214}
              </span>
            </div>
          </div>
          <div className="pricing-development-container17">
            <div className="pricing-development-container18">
              <span className="thq-body-small">{props.feature4}</span>
            </div>
            <div className="pricing-development-container19">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
            <div className="pricing-development-container20">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
            <div className="pricing-development-container21">
              <svg
                viewBox="0 0 1024 1024"
                className="pricing-development-icon04 thq-icon-small"
              >
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
          </div>
          <div className="pricing-development-container22">
            <div className="pricing-development-container23">
              <span className="thq-body-small">{props.feature5}</span>
            </div>
            <div className="pricing-development-container24">
              <span className="thq-body-small">{props.feature23}</span>
            </div>
            <div className="pricing-development-container25">
              <span className="thq-body-small">{props.feature231}</span>
            </div>
            <div className="pricing-development-container26">
              <span className="pricing-development-text28 thq-body-small">
                {props.feature232}
              </span>
            </div>
          </div>
        </div>
        <div className="pricing-development-container27">
          <div className="pricing-development-content21">
            <h3 className="thq-heading-3">{props.featureCategory2}</h3>
          </div>
          <div className="pricing-development-container28">
            <div className="pricing-development-container29">
              <span className="thq-body-small">{props.feature6}</span>
            </div>
            <div className="pricing-development-container30">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
            <div className="pricing-development-container31">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
            <div className="pricing-development-container32">
              <svg
                viewBox="0 0 1024 1024"
                className="pricing-development-icon10 thq-icon-small"
              >
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
          </div>
          <div className="pricing-development-container33">
            <div className="pricing-development-container34">
              <span className="thq-body-small">{props.feature63}</span>
            </div>
            <div className="pricing-development-container35">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
            <div className="pricing-development-container36">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
            <div className="pricing-development-container37">
              <svg
                viewBox="0 0 1024 1024"
                className="pricing-development-icon16 thq-icon-small"
              >
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
          </div>
          <div className="pricing-development-container38">
            <div className="pricing-development-container39">
              <span className="thq-body-small">{props.feature62}</span>
            </div>
            <div className="pricing-development-container40">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
            <div className="pricing-development-container41">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
            <div className="pricing-development-container42">
              <svg
                viewBox="0 0 1024 1024"
                className="pricing-development-icon22 thq-icon-small"
              >
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
          </div>
          <div className="pricing-development-container43">
            <div className="pricing-development-container44">
              <span className="thq-body-small">{props.feature61}</span>
            </div>
            <div className="pricing-development-container45">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
            <div className="pricing-development-container46">
              <span className="pricing-development-text34 thq-body-small">
                {props.count411}
              </span>
            </div>
            <div className="pricing-development-container47">
              <span className="pricing-development-text35 thq-body-small">
                {props.count412}
              </span>
            </div>
          </div>
          <div className="pricing-development-container48">
            <div className="pricing-development-container49">
              <span className="thq-body-small">{props.feature7}</span>
            </div>
            <div className="pricing-development-container50">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
            <div className="pricing-development-container51">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
            <div className="pricing-development-container52">
              <svg
                viewBox="0 0 1024 1024"
                className="pricing-development-icon30 thq-icon-small"
              >
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
          </div>
          <div className="pricing-development-container53">
            <div className="pricing-development-container54">
              <span className="thq-body-small">{props.feature8}</span>
            </div>
            <div className="pricing-development-container55">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
            <div className="pricing-development-container56">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
            <div className="pricing-development-container57">
              <svg
                viewBox="0 0 1024 1024"
                className="pricing-development-icon36 thq-icon-small"
              >
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
          </div>
          <div className="pricing-development-container58">
            <div className="pricing-development-container59">
              <span className="thq-body-small">{props.feature9}</span>
            </div>
            <div className="pricing-development-container60">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
            <div className="pricing-development-container61">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
            <div className="pricing-development-container62">
              <svg
                viewBox="0 0 1024 1024"
                className="pricing-development-icon42 thq-icon-small"
              >
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
          </div>
          <div className="pricing-development-container63">
            <div className="pricing-development-container64">
              <span className="thq-body-small">{props.feature10}</span>
            </div>
            <div className="pricing-development-container65">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
            <div className="pricing-development-container66">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M213.333 554.667h597.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-597.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
            <div className="pricing-development-container67">
              <svg
                viewBox="0 0 1024 1024"
                className="pricing-development-icon48 thq-icon-small"
              >
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="pricing-development-container68">
        <div className="pricing-development-container69">
          <span>
            {props.text1 ?? (
              <Fragment>
                <span className="pricing-development-text42">
                  Want to have your own custom plan, purchase domain or just
                  host your existing website on our reliable, secure and fast
                  servers?
                </span>
              </Fragment>
            )}
          </span>
          <Link
            to="/contact-us"
            className="pricing-development-button thq-button-animated thq-button-filled"
          >
            <span className="pricing-development-text41 thq-body-small">
              {props.plan2Action2}
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

PricingDevelopment.defaultProps = {
  plan2Action2: "Get started",
  feature1: "Website Design",
  feature21: "1",
  feature211: "3 ",
  feature5: "Support and Maintenance",
  feature62: "SSL Certificate",
  subtitle1: "Choose the plan that fits your business needs",
  count412: "Basic",
  plan2Price11: "onwards",
  feature63: "Hosting",
  count3: "Custom Design",
  feature213: "4 - 6",
  plan1Price: "$99 ",
  feature214: "10 - 12",
  rootClassName: "",
  featureCategory1: "Basic Features",
  feature9: "Payment Gateway Integration",
  text1: undefined,
  count411: "Basic",
  feature22: "1 - 2",
  feature7: "Live Chat Integration",
  plan3: "Ecommerce Website",
  plan2Price12: "onwards",
  feature2: "Design Art Revision",
  feature61: "Search Engine Optimized",
  plan2Price: "$199",
  count1: "Basic Design",
  feature232: "1 year",
  plan2: "Advanced Website",
  feature23: "1 month ",
  feature212: "5",
  count2: "Custom Design\n",
  plan1: "Basic Website",
  feature4: "Responsive Design",
  heading1: "Website Development",
  featureCategory2: "Advanced Features",
  feature8: "Contact Form",
  feature231: "6 month",
  feature3: "Pages",
  feature6: "Domain",
  plan2Price1: "onwards",
  feature10: "Login System",
  text: undefined,
};

PricingDevelopment.propTypes = {
  plan2Action2: PropTypes.string,
  feature1: PropTypes.string,
  feature21: PropTypes.string,
  feature211: PropTypes.string,
  feature5: PropTypes.string,
  feature62: PropTypes.string,
  subtitle1: PropTypes.string,
  count412: PropTypes.string,
  plan2Price11: PropTypes.string,
  feature63: PropTypes.string,
  count3: PropTypes.string,
  feature213: PropTypes.string,
  plan1Price: PropTypes.string,
  feature214: PropTypes.string,
  rootClassName: PropTypes.string,
  featureCategory1: PropTypes.string,
  feature9: PropTypes.string,
  text1: PropTypes.element,
  count411: PropTypes.string,
  feature22: PropTypes.string,
  feature7: PropTypes.string,
  plan3: PropTypes.string,
  plan2Price12: PropTypes.string,
  feature2: PropTypes.string,
  feature61: PropTypes.string,
  plan2Price: PropTypes.string,
  count1: PropTypes.string,
  feature232: PropTypes.string,
  plan2: PropTypes.string,
  feature23: PropTypes.string,
  feature212: PropTypes.string,
  count2: PropTypes.string,
  plan1: PropTypes.string,
  feature4: PropTypes.string,
  heading1: PropTypes.string,
  featureCategory2: PropTypes.string,
  feature8: PropTypes.string,
  feature231: PropTypes.string,
  feature3: PropTypes.string,
  feature6: PropTypes.string,
  plan2Price1: PropTypes.string,
  feature10: PropTypes.string,
  text: PropTypes.element,
};

export default PricingDevelopment;
