import React, { Fragment } from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import DigitalMHeader from "../components/digital-m-header";
import DigitalMContent from "../components/digital-m-content";
import Footer from "../components/footer";
import "./digital-marketing.css";

const DigitalMarketing = (props) => {
  return (
    <div className="digital-marketing-container">
      <Helmet>
        <title>Digital-Marketing - DPS Tek</title>
        <meta property="og:title" content="Digital-Marketing - DPS Tek" />
      </Helmet>
      <Navbar></Navbar>
      <DigitalMHeader
        text={
          <Fragment>
            <h1 className="digital-marketing-text thq-heading-1">
              Digital Marketing
            </h1>
          </Fragment>
        }
        text1={
          <Fragment>
            <p className="digital-marketing-text01 thq-body-large">
              Choose from our wide variety of plans or reach us for a custom
              plan to carter your business needs
            </p>
          </Fragment>
        }
        rootClassName="digital-m-header-root-class-name"
      ></DigitalMHeader>
      <DigitalMContent
        content1="Digital marketing encompasses all marketing efforts that use the internet or an electronic device. Businesses leverage digital channels such as search engines, social media, email, and their websites to connect with current and prospective customers."
        heading1="Digital Marketing"
        content11={
          <Fragment>
            <p className="digital-marketing-content1 thq-body-small">
              Digital marketing encompasses all marketing efforts that use the
              internet or an electronic device. Businesses leverage digital
              channels such as search engines, social media, email, and their
              websites to connect with current and prospective customers.
            </p>
          </Fragment>
        }
        content22={
          <Fragment>
            <p className="digital-marketing-content2 thq-body-small">
              <span>
                It is often said in marketing that the best way to market your
                products &amp; services is to be at a place where your potential
                customers are-at present, it is social media. With nearly 58% of
                the world’s population being active on social media, indeed
                social media marketing has become the need of the hour.
              </span>
              <br />
              <br />
              <span>Social Media Marketing is defined as:</span>
              <br />
              <span className="digital-marketing-text07">
                “The process of utilizing social media websites and networks to
                promote a company’s products or services.”
              </span>
              <br />
              <br />
              <span>A more refined version is here:</span>
              <br />
              <span className="digital-marketing-text12">
                “Social media marketing involves the creation of customized
                content for each social media platform to fuel engagement and
                promote your business.”
              </span>
              <br />
              <br />
              <span>
                Another reason why social media marketing has become immensely
                important as the daily average time spent by a user is around
                2.5 hours.
              </span>
              <br />
              <br />
              <span>
                Social media platforms allow businesses to establish a personal
                connection with their existing and probable customers. It is
                more like a wonderful opportunity for brands to present
                themselves beautifully, which eventually leads to tremendous
                business growth.
              </span>
              <br />
              <br />
              <span>
                You can consider it as a situation where you meet a person for
                the first time. You would surely try everything possible to
                leave a lasting impression. Social media is just like that. You
                meet people virtually to leave an impression. Social media
                marketing helps in doing just that. But when it comes to
                creating content that is compelling enough to engage the
                audience, things are not easy. This is where choosing a reputed
                social media marketing agency like DPS Tek is important to edge
                up creativity and increase your business exposure.
              </span>
              <br />
              <br />
              <span>
                Our social media marketing experts understand all aspects of
                social media marketing and carve out a foolproof social media
                marketing strategy to power up your campaign.
              </span>
            </p>
          </Fragment>
        }
        content31={
          <Fragment>
            <p className="digital-marketing-content3 thq-body-small">
              <span>
                Social media optimization services can help businesses by
                developing and executing a plan that will improve the visibility
                and effectiveness of their social media accounts. SMO services
                can help businesses to increase the number of followers they
                have on social media, as well as to better engage with their
                current followers. SMO services can also help businesses to
                create more effective content, which in turn will help them to
                attract more followers and generate more leads.
              </span>
              <br />
              <br />
              <span>
                Social media optimization is the process of improving the
                visibility and reach of a website or web page on social media
                platforms. This can be done by optimizing the website or web
                page content, as well as the social media platform profiles and
                pages. Additionally, social media optimization includes using
                social media tools and techniques to improve the visibility and
                reach of a website or web page.
              </span>
            </p>
          </Fragment>
        }
        content41={
          <Fragment>
            <p className="digital-marketing-content4 thq-body-small">
              <span>
                Search Engine Optimization (SEO) is a strategy which allows you
                to improve the visibility of your website on the search
                engine&apos;s and rank you higher. It helps drive a huge amount
                of traffic to your website which can convert to high-value
                sales.
              </span>
              <br />
              <br />
              <span>Some of the reasons to do SEO are as follows:</span>
              <br />
              <br />
              <span className="digital-marketing-text35">
                ➤  Organic Search Is A Primary Source Of Website Traffic: 
              </span>
              <span>
                Organic search results drive the majority of website traffic,
                and studies show that over 50% of website traffic comes from
                organic search. This means that if your website is not optimized
                for search engines, you’re missing out on a significant amount
                of potential traffic.
              </span>
              <br></br>
              <span className="digital-marketing-text40">
                ➤  Helps Build Trust And Credibility: 
              </span>
              <span>
                People trust search engines, and if your website ranks high in
                search results, it can help build trust and credibility with
                your target audience. High search engine rankings indicate that
                your website is a relevant and authoritative source of
                information.
              </span>
              <br></br>
              <span className="digital-marketing-text45">
                ➤  Improves User Experience And Engagement On Website: 
              </span>
              <span>
                SEO involves optimizing your website’s structure and content to
                make it easy for search engines to understand and index your
                pages. This also improves the user experience by making your
                website more organized, informative, and user-friendly.
              </span>
              <br></br>
            </p>
          </Fragment>
        }
      ></DigitalMContent>
      <Footer rootClassName="footer-root-class-name9"></Footer>
    </div>
  );
};

export default DigitalMarketing;
