import React, { Fragment } from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import CloudHeader from "../components/cloud-header";
import CloudContent from "../components/cloud-content";
import Footer from "../components/footer";
import "./cloud-services.css";

const CloudServices = (props) => {
  return (
    <div className="cloud-services-container">
      <Helmet>
        <title>Cloud Services - DPS Tek</title>
        <meta property="og:title" content="Cloud-Services - DPS Tek" />
      </Helmet>
      <Navbar></Navbar>
      <CloudHeader
        text={
          <Fragment>
            <h1 className="cloud-services-text thq-heading-1">
              Cloud Services
            </h1>
          </Fragment>
        }
        text1={
          <Fragment>
            <p className="cloud-services-text001 thq-body-large">
              Elevate Your Business with Cutting-Edge Cloud Solutions
            </p>
          </Fragment>
        }
      ></CloudHeader>
      <CloudContent
        content1={
          <Fragment>
            <p className="cloud-services-content1 thq-body-small">
              <span className="cloud-services-text002">
                Welcome to DPS Tek, your trusted partner for cloud solutions.
                Whether you are looking to migrate, manage, or optimize your
                cloud infrastructure, we provide comprehensive services across
                Google Cloud Platform (GCP), Microsoft Azure, and Amazon Web
                Services (AWS). Let us help you unlock the full potential of the
                cloud to drive innovation, efficiency, and growth.
              </span>
              <br />
              <br />
              <br />
              <br />
              <span className="cloud-services-text005">
                What is Cloud and why you need it?
              </span>
              <br></br>
              <br></br>
              <span className="cloud-services-text009">
                The cloud is a network of remote servers hosted on the internet
                to store, manage, and process data. Unlike traditional
                on-premise infrastructure, cloud computing allows for scalable
                resources and services that can be accessed on-demand, providing
                flexibility and efficiency for businesses of all sizes.
                <br />
                <br />
                Cloud computing offers numerous benefits including cost savings,
                scalability, flexibility, disaster recovery, and remote access.
                By leveraging cloud services, businesses can reduce IT costs,
                scale resources according to demand, improve collaboration, and
                ensure data security and compliance.
              </span>

              <br />
              <br />
              <br />
              <br />

              <span className="cloud-services-text015">Why Us?</span>

              <br />
              <br />

              <span className="cloud-services-text018">
                At DPS Tek, we understand that every business is unique. Our
                tailored approach ensures that your digital marketing strategy
                is aligned with your specific business objectives. Here’s what
                sets us apart:
              </span>
              <br />
              <br />
              <span className="cloud-services-text021">
                ➤ Expertise Across Major Cloud Platforms: 
              </span>
              <span className="cloud-services-text025">
                Our certified cloud architects and engineers have extensive
                experience with GCP, Azure, and AWS, ensuring we can meet your
                unique business needs.
              </span>
              <br />
              <span className="cloud-services-text026">
                ➤  Customized Solutions: 
              </span>
              <span className="cloud-services-text029">
                We tailor our cloud services to align with your business goals,
                providing personalized solutions that drive success.
              </span>
              <br />
              <span className="cloud-services-text030">
                ➤   Proven Track Record: 
              </span>
              <span className="cloud-services-text031">
                We have a history of successful cloud projects across various
                industries, demonstrating our ability to deliver high-quality
                results.
              </span>
              <br />
              <span className="cloud-services-text034">
                ➤  End-to-End Support: 
              </span>
              <span className="cloud-services-text035">
                From initial consultation and planning to deployment and ongoing
                support, we are with you every step of the way.
              </span>

              <br />
              <br />
              <br />
              <br />

              <span className="cloud-services-text039">
                What Cloud Platforms do we work on?
              </span>

              <br></br>
              <br></br>

              <span className="cloud-services-text042">
                Google Cloud Platform (GCP)
              </span>
              <br></br>
              <span className="cloud-services-text042-1">
                Harness the Power of Google’s Cloud for Your Business
              </span>
              <br></br>
              <span className="cloud-services-text047">
                   •  Cloud Migration:{" "}
              </span>
              <span className="cloud-services-text048">
                Seamlessly move your applications, data, and workloads to GCP
                with minimal downtime and disruption.
              </span>
              <br></br>
              <span className="cloud-services-text051">
                   •   Infrastructure Modernization:{" "}
              </span>
              <span className="cloud-services-text052">
                Modernize your IT infrastructure with GCP’s robust and scalable
                cloud services, including Compute Engine, Kubernetes Engine, and
                Cloud Storage.
              </span>
              <br></br>
              <span className="cloud-services-text055">
                   •  Data Analytics &amp; AI:{" "}
              </span>
              <span className="cloud-services-text056">
                Leverage GCP’s advanced data analytics and AI tools to gain
                deeper insights and drive smarter decision-making.
              </span>
              <br></br>
              <span className="cloud-services-text059">
                   •  Security &amp; Compliance: 
              </span>
              <span className="cloud-services-text060">
                Ensure your cloud environment is secure and compliant with
                industry standards using GCP’s comprehensive security features
                and tools.
              </span>
              <br />
              <br />
              <br />
              <span className="cloud-services-text064">Microsoft Azure</span>
              <br></br>
              <span className="cloud-services-text064-1">
                Empower Your Enterprise with Microsoft’s Azure Cloud Solutions
              </span>
              <br></br>
              <span className="cloud-services-text068">
                   •  Hybrid Cloud Solutions:{" "}
              </span>
              <span className="cloud-services-text069">
                Integrate your on-premises and cloud environments seamlessly
                with Azure’s hybrid cloud capabilities.
              </span>
              <br></br>
              <span className="cloud-services-text072">
                   •  DevOps &amp; Automation:{" "}
              </span>
              <span className="cloud-services-text073">
                Accelerate your development and operations with Azure’s DevOps
                tools, enabling continuous integration and delivery.
              </span>
              <br></br>
              <span className="cloud-services-text076">
                   •  AI &amp; Machine Learning:{" "}
              </span>
              <span className="cloud-services-text077">
                Innovate with Azure’s AI and machine learning services to build
                intelligent applications and solutions.
              </span>
              <br></br>
              <span className="cloud-services-text080">
                   •  Business Continuity &amp; Disaster Recovery:{" "}
              </span>
              <span className="cloud-services-text081">
                Protect your critical applications and data with Azure’s
                reliable backup and disaster recovery solutions.
              </span>
              <br />
              <br />
              <br />
              <span className="cloud-services-text085">
                Amazon Web Services (AWS)
              </span>
              <br></br>
              <span className="cloud-services-text085-1">
                Build, Innovate, and Scale with AWS Cloud Services
              </span>
              <br></br>
              <span className="cloud-services-text089">
                   •  Cloud Infrastructure:{" "}
              </span>
              <span className="cloud-services-text090">
                Deploy and manage scalable and flexible infrastructure with
                AWS’s wide range of services, including EC2, S3, and RDS.
              </span>
              <br></br>
              <span className="cloud-services-text093">
                   •  Serverless Computing:{" "}
              </span>
              <span className="cloud-services-text094">
                Simplify application development and reduce operational overhead
                with AWS’s serverless computing services like Lambda and
                Fargate.
              </span>
              <br></br>
              <span className="cloud-services-text097">
                   •  Big Data &amp; Analytics:{" "}
              </span>
              <span className="cloud-services-text098">
                Transform your data into actionable insights with AWS’s powerful
                big data and analytics services, such as Redshift and Kinesis.
              </span>
              <br />
              <span className="cloud-services-text101">
                   •  Cost Management:{" "}
              </span>
              <span className="cloud-services-text102">
                Optimize your cloud spending with AWS’s cost management tools,
                ensuring you get the most value from your cloud investment.
              </span>
              <br></br>
            </p>
            <br />
            <br />
            <br />
          </Fragment>
        }
        content2={
          <Fragment>
            <p className="cloud-services-text105 thq-body-small">
              Unlock the full potential of your business with our expert cloud
              consultation services. Our team of certified professionals will
              work closely with you to understand your unique needs and
              challenges, providing tailored strategies that leverage the best
              of GCP, AWS, and Azure. We offer comprehensive assessments and
              actionable roadmaps to ensure your cloud journey is aligned with
              your business objectives, maximizing efficiency and innovation.
            </p>
          </Fragment>
        }
        content3={
          <Fragment>
            <p className="cloud-services-text106 thq-body-small">
              Transition seamlessly to the cloud with our structured and
              efficient cloud onboarding services. We ensure a smooth migration
              process to GCP, AWS, or Azure, minimizing downtime and disruption
              to your operations. Our specialists will guide you through every
              step, from initial setup and configuration to data migration and
              application deployment, ensuring your cloud environment is
              optimized for performance and scalability.
            </p>
          </Fragment>
        }
        content4={
          <Fragment>
            <p className="cloud-services-text107 thq-body-small">
              Protect your digital assets with our robust cloud security
              solutions. We offer comprehensive security assessments,
              implementation of advanced security measures, and continuous
              monitoring to safeguard your data and applications on GCP, AWS,
              and Azure. Our services include identity and access management,
              threat detection and response, and compliance management, ensuring
              your cloud infrastructure meets the highest standards of security.
            </p>
          </Fragment>
        }
        heading2={
          <Fragment>
            <h3 className="cloud-services-text108 thq-heading-3">
              Cloud based Services at DPS Tek include:
            </h3>
          </Fragment>
        }
        heading3={
          <Fragment>
            <h3 className="cloud-services-text109 thq-heading-3">Onboarding</h3>
          </Fragment>
        }
        heading4={
          <Fragment>
            <h3 className="cloud-services-text110 thq-heading-3">Security</h3>
          </Fragment>
        }
        content41={
          <Fragment>
            <p className="cloud-services-text111 thq-body-small">
              Maximize your cloud investment with our cloud cost optimization
              services. We provide in-depth analysis and actionable insights to
              help you manage and reduce your cloud spending on GCP, AWS, and
              Azure. Our experts will identify inefficiencies, recommend
              cost-saving measures, and implement automated solutions to ensure
              you get the best value from your cloud services without
              compromising performance or scalability.
            </p>
          </Fragment>
        }
        content42={
          <Fragment>
            <p className="cloud-services-text112 thq-body-small">
              Design and implement a robust cloud infrastructure with our cloud
              architecture and migration services. Our team of experienced
              architects will help you build a scalable, resilient, and
              high-performance cloud environment on GCP, AWS, or Azure. We offer
              end-to-end migration services, from initial planning and design to
              execution and optimization, ensuring a smooth transition with
              minimal disruption to your business operations.
            </p>
          </Fragment>
        }
        content43={
          <Fragment>
            <p className="cloud-services-text113 thq-body-small">
              Achieve the perfect balance between on-premises and cloud
              environments with our hybrid cloud solutions. We provide
              customized strategies that integrate your existing infrastructure
              with GCP, AWS, or Azure, enabling seamless data flow and
              application interoperability. Our solutions offer flexibility,
              scalability, and enhanced security, allowing you to leverage the
              best of both worlds while maintaining control over your critical
              workloads.
            </p>
          </Fragment>
        }
        content44={
          <Fragment>
            <p className="cloud-services-text114 thq-body-small">
              Transform your legacy applications with our cloud application
              modernization services. We help you update and refactor your
              applications to take full advantage of the capabilities offered by
              GCP, AWS, and Azure. Our services include containerization,
              microservices architecture, and serverless computing, enabling you
              to enhance performance, scalability, and agility while reducing
              operational costs.
            </p>
          </Fragment>
        }
        content45={
          <Fragment>
            <p className="cloud-services-text115 thq-body-small">
              Empower your team with our comprehensive cloud training and
              certification programs. We offer a range of courses designed to
              equip your staff with the knowledge and skills needed to
              effectively manage and utilize GCP, AWS, and Azure services. Our
              training programs cover all levels, from foundational to advanced,
              and include hands-on labs, real-world scenarios, and official
              certification preparation to ensure your team is cloud-ready.
            </p>
          </Fragment>
        }
        heading21={
          <Fragment>
            <h3 className="cloud-services-text116 thq-heading-3">
              ➤ Consultation
            </h3>
          </Fragment>
        }
        heading22={
          <Fragment>
            <h3 className="cloud-services-text117 thq-heading-3">
              ➤ Onboarding
            </h3>
          </Fragment>
        }
        heading23={
          <Fragment>
            <h3 className="cloud-services-text118 thq-heading-3">➤ Security</h3>
          </Fragment>
        }
        heading24={
          <Fragment>
            <h3 className="cloud-services-text119 thq-heading-3">
              ➤ Cost Optimization
            </h3>
          </Fragment>
        }
        heading25={
          <Fragment>
            <h3 className="cloud-services-text120 thq-heading-3">
              ➤ Architecture &amp; Migration
            </h3>
          </Fragment>
        }
        heading26={
          <Fragment>
            <h3 className="cloud-services-text121 thq-heading-3">
              ➤ Hybrid Cloud Solutions
            </h3>
          </Fragment>
        }
        heading27={
          <Fragment>
            <h3 className="cloud-services-text122 thq-heading-3">
              ➤ Application Modrenization
            </h3>
          </Fragment>
        }
        heading28={
          <Fragment>
            <h3 className="cloud-services-text123 thq-heading-3">
              ➤ Training &amp; Certification
            </h3>
          </Fragment>
        }
        heading29={
          <Fragment>
            <h3 className="cloud-services-text124 thq-heading-3">
              Consultation
            </h3>
          </Fragment>
        }
        heading41={
          <Fragment>
            <h3 className="cloud-services-text125 thq-heading-3">
              Cost Optimization
            </h3>
          </Fragment>
        }
        heading42={
          <Fragment>
            <h3 className="cloud-services-text126 thq-heading-3">
              Architecture &amp; Migration
            </h3>
          </Fragment>
        }
        heading43={
          <Fragment>
            <h3 className="cloud-services-text127 thq-heading-3">
              Hybrid Cloud Solution
            </h3>
          </Fragment>
        }
        heading44={
          <Fragment>
            <h3 className="cloud-services-text128 thq-heading-3">
              Application Modernization
            </h3>
          </Fragment>
        }
        heading45={
          <Fragment>
            <h3 className="cloud-services-text129 thq-heading-3">
              Training &amp; Certification
            </h3>
          </Fragment>
        }
      ></CloudContent>
      <Footer rootClassName="footer-root-class-name3"></Footer>
    </div>
  );
};

export default CloudServices;
