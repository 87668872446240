import React, { Fragment } from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import Header from "../components/header";
import Footer from "../components/footer";
import "./not-found.css";

const NotFound1 = (props) => {
  return (
    <div className="not-found1-container">
      <Helmet>
        <title>DPS Tek</title>
      </Helmet>
      <Navbar
        imageSrc="/dps%20tek%20logo%20name-400h.webp"
        rootClassName="navbar-root-class-name3"
      ></Navbar>
      <Header
        heading={
          <Fragment>
            <h3 className="not-found1-text">OOPS! PAGE NOT FOUND</h3>
          </Fragment>
        }
        heading1={
          <Fragment>
            <h1 className="not-found1-text1">404</h1>
          </Fragment>
        }
        heading2={
          <Fragment>
            <h2 className="not-found1-text2">
              WE ARE SORRY, BUT THE PAGE YOU REQUESTED WAS NOT FOUND
            </h2>
          </Fragment>
        }
      ></Header>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  );
};

export default NotFound1;
