import React, { Fragment } from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import WebDHeader from "../components/web-d-header";
import WebDContent from "../components/web-d-content";
import Footer from "../components/footer";
import "./web-development.css";

const WebDevelopment = (props) => {
  return (
    <div className="web-development-container">
      <Helmet>
        <title>Web-Development - DPS Tek</title>
        <meta property="og:title" content="Web-Development - DPS Tek" />
      </Helmet>
      <Navbar></Navbar>
      <WebDHeader
        text={
          <Fragment>
            <h1 className="web-development-text thq-heading-1">SEO Services</h1>
          </Fragment>
        }
        text1={
          <Fragment>
            <p className="web-development-text01 thq-body-large">
              Choose from our wide variety of plans or reach us for a custom
              plan to carter your business needs
            </p>
          </Fragment>
        }
      ></WebDHeader>
      <WebDContent
        content1={
          <Fragment>
            <p className="web-development-content1 thq-body-small">
              <span>
                At DPS Tek, we specialize in crafting bespoke websites that not
                only look stunning but also deliver exceptional user
                experiences. Whether you&apos;re a startup looking to establish
                an online presence or an established business seeking a website
                overhaul, our team of skilled designers and developers is here
                to bring your vision to life.
              </span>
              <br className="web-development-text03"></br>
            </p>
          </Fragment>
        }
        content2={
          <Fragment>
            <p className="web-development-text04 thq-body-small">
              Static website development is the ideal solution for businesses
              and individuals looking to establish a straightforward and
              professional online presence. These websites consist of fixed
              content, which means each page is pre-built and displays the same
              information to every visitor. This simplicity not only ensures
              faster load times and enhanced security but also reduces
              maintenance requirements. Perfect for portfolios, informational
              sites, and small business pages, our static websites deliver a
              seamless user experience while keeping costs manageable.
            </p>
          </Fragment>
        }
        content3={
          <Fragment>
            <p className="web-development-content3 thq-body-small">
              <span>
                Dynamic website development offers a more interactive and
                engaging user experience by generating content on-the-fly based
                on user interactions or data inputs. These websites are built
                using server-side technologies and databases, allowing for
                features such as user accounts, personalized content, and
                real-time updates. Whether you need a blog, a membership site,
                or a complex web application, our dynamic website solutions are
                designed to adapt to your growing needs, ensuring your online
                presence remains fresh and relevant.
              </span>
              <br></br>
            </p>
          </Fragment>
        }
        content4={
          <Fragment>
            <p className="web-development-text07 thq-body-small">
              Ecommerce website development empowers businesses to sell products
              and services online, reaching a global audience and operating
              24/7. Our ecommerce solutions are crafted to provide a seamless
              shopping experience, featuring secure payment gateways, intuitive
              product navigation, and efficient checkout processes. We integrate
              advanced functionalities such as inventory management, customer
              reviews, and personalized recommendations to enhance user
              satisfaction and boost sales. From small online stores to
              large-scale marketplaces, we tailor our ecommerce platforms to
              meet your specific business requirements.
            </p>
          </Fragment>
        }
        heading1={
          <Fragment>
            <h2 className="web-development-text08 thq-heading-2">
              Transforming Ideas into Digital Experiences
            </h2>
          </Fragment>
        }
        heading2={
          <Fragment>
            <h3 className="web-development-text09 thq-heading-3">
              Website Development Services at DPS Tek include:
            </h3>
          </Fragment>
        }
        heading3={
          <Fragment>
            <h3 className="web-development-text10 thq-heading-3">
              Dynamic Website Development
            </h3>
          </Fragment>
        }
        heading4={
          <Fragment>
            <h3 className="web-development-text11 thq-heading-3">
              E-Commerce Website Development
            </h3>
          </Fragment>
        }
        content41={
          <Fragment>
            <p className="web-development-content4 thq-body-small">
              <span>
                Effective domain management and reliable hosting are crucial for
                maintaining a robust online presence. We offer comprehensive
                domain management services, including registration, renewal, and
                transfer of domains, ensuring your web address is always secure
                and functional. Our hosting solutions guarantee high
                performance, minimal downtime, and scalability to handle
                increasing traffic. With our managed hosting plans, you can
                focus on your core business activities while we take care of
                server maintenance, updates, and backups, providing a
                hassle-free experience and peace of mind.
              </span>
              <br></br>
            </p>
          </Fragment>
        }
        heading21={
          <Fragment>
            <h3 className="web-development-text14 thq-heading-3">
              ➤ Static Website Development
            </h3>
          </Fragment>
        }
        heading22={
          <Fragment>
            <h3 className="web-development-text15 thq-heading-3">
              ➤ Dynamic Website Development
            </h3>
          </Fragment>
        }
        heading23={
          <Fragment>
            <h3 className="web-development-text16 thq-heading-3">
              ➤ E-Commerce Website Development
            </h3>
          </Fragment>
        }
        heading24={
          <Fragment>
            <h3 className="web-development-text17 thq-heading-3">
              ➤ Domain Management &amp; Hosting
            </h3>
          </Fragment>
        }
        heading25={
          <Fragment>
            <h3 className="web-development-text18 thq-heading-3">
              Static Website Development
            </h3>
          </Fragment>
        }
        heading41={
          <Fragment>
            <h3 className="web-development-text19 thq-heading-3">
              Domain Management &amp; Hosting
            </h3>
          </Fragment>
        }
      ></WebDContent>
      <Footer rootClassName="footer-root-class-name11"></Footer>
    </div>
  );
};

export default WebDevelopment;
