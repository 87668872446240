import React, { Fragment } from "react";

import PropTypes from "prop-types";

import "./digital-m-content.css";

const DigitalMContent = (props) => {
  return (
    <div
      className={`digital-m-content-container thq-section-padding ${props.rootClassName} `}
    >
      <div className="digital-m-content-max-width thq-section-max-width">
        <div className="digital-m-content-content thq-flex-column">
          <ul className="digital-m-content-ul thq-flex-column">
            <li className="thq-flex-column list-item">
              <p>
                {props.content11 ?? (
                  <Fragment>
                    <p className="digital-m-content-content11 thq-body-small">
                      <span className="digital-m-content-text43">
                        Digital marketing encompasses all marketing efforts that
                        use the internet or an electronic device. Businesses
                        leverage digital channels such as search engines, social
                        media, email, and their websites to connect with current
                        and prospective customers.
                      </span>
                      <br />
                      <br />
                      <span className="digital-m-content-text46">
                        At DPS Tek, we understand that every business is unique.
                        Our tailored approach ensures that your digital
                        marketing strategy is aligned with your specific
                        business objectives. Here’s what sets us apart:
                      </span>
                      <br />
                      <span className="digital-m-content-text48">
                        ➤  Expert Team:
                      </span>
                      <span className="digital-m-content-text49">
                        {" "}
                        Our team consists of seasoned professionals with
                        extensive experience in the digital marketing landscape.
                      </span>
                      <br />
                      <span className="digital-m-content-text51">
                        ➤  Customized Strategies:
                      </span>
                      <span className="digital-m-content-text53">
                        {" "}
                        We don’t believe in one-size-fits-all solutions. Each
                        strategy is customized to meet the unique needs of your
                        business.
                      </span>
                      <br />
                      <span className="digital-m-content-text55">
                        ➤  Proven Results:
                      </span>
                      <span className="digital-m-content-text57">
                        {" "}
                        We have a track record of delivering measurable results
                        that drive business growth.
                      </span>
                      <br></br>
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="list-item">
              <h3 className="digital-m-content-heading2 thq-heading-3">
                {props.heading2}
              </h3>
              <h3 className="thq-heading-3">{props.heading22}</h3>
              <h3 className="thq-heading-3">{props.heading2214}</h3>
              <h3 className="thq-heading-3">{props.heading22131}</h3>
            </li>
            <br />
            <div className="digital-m-horiz-line"></div>
            <br />
            <li className="list-item">
              <h3 className="digital-m-content-heading1">{props.heading21}</h3>
              <p>
                <br />
                <br />
                {props.content22 ?? (
                  <Fragment>
                    <p className="digital-m-content-content21 thq-body-small">
                      <span>
                        It is often said in marketing that the best way to
                        market your products &amp; services is to be at a place
                        where your potential customers are-at present, it is
                        social media. With nearly 58% of the world’s population
                        being active on social media, indeed social media
                        marketing has become the need of the hour.
                      </span>
                      <br></br>
                      <br></br>
                      <span>Social Media Marketing is defined as:</span>
                      <br></br>
                      <span className="digital-m-content-text25">
                        “The process of utilizing social media websites and
                        networks to promote a company’s products or services.”
                      </span>
                      <br></br>
                      <br></br>
                      <span>A more refined version is here:</span>
                      <br></br>
                      <span className="digital-m-content-text30">
                        “Social media marketing involves the creation of
                        customized content for each social media platform to
                        fuel engagement and promote your business.”
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        Another reason why social media marketing has become
                        immensely important as the daily average time spent by a
                        user is around 2.5 hours.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        Social media platforms allow businesses to establish a
                        personal connection with their existing and probable
                        customers. It is more like a wonderful opportunity for
                        brands to present themselves beautifully, which
                        eventually leads to tremendous business growth.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        You can consider it as a situation where you meet a
                        person for the first time. You would surely try
                        everything possible to leave a lasting impression.
                        Social media is just like that. You meet people
                        virtually to leave an impression. Social media marketing
                        helps in doing just that. But when it comes to creating
                        content that is compelling enough to engage the
                        audience, things are not easy. This is where choosing a
                        reputed social media marketing agency like DPS Tek is
                        important to edge up creativity and increase your
                        business exposure.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        Our social media marketing experts understand all
                        aspects of social media marketing and carve out a
                        foolproof social media marketing strategy to power up
                        your campaign.
                      </span>
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <br />
            <div className="digital-m-horiz-line"></div>
            <br />
            <li className="list-item">
              <h3 className="digital-m-content-heading1">{props.heading3}</h3>
              <br />
              <br />
              <p>
                {props.content31 ?? (
                  <Fragment>
                    <p className="digital-m-content-content31 thq-body-small">
                      <br></br>
                      <span>
                        Social media optimization services can help businesses
                        by developing and executing a plan that will improve the
                        visibility and effectiveness of their social media
                        accounts. SMO services can help businesses to increase
                        the number of followers they have on social media, as
                        well as to better engage with their current followers.
                        SMO services can also help businesses to create more
                        effective content, which in turn will help them to
                        attract more followers and generate more leads.
                      </span>
                      <br></br>
                      <span>
                        Social media optimization is the process of improving
                        the visibility and reach of a website or web page on
                        social media platforms. This can be done by optimizing
                        the website or web page content, as well as the social
                        media platform profiles and pages. Additionally, social
                        media optimization includes using social media tools and
                        techniques to improve the visibility and reach of a
                        website or web page.
                      </span>
                      <br></br>
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
            <br />
            <div className="digital-m-horiz-line"></div>
            <br />
            <li className="list-item">
              <h3 className="digital-m-content-heading1">{props.heading4}</h3>
              <br />
              <br />
              <p>
                {props.content41 ?? (
                  <Fragment>
                    <p className="digital-m-content-content41 thq-body-small">
                      <span>
                        Search Engine Optimization (SEO) is a strategy which
                        allows you to improve the visibility of your website on
                        the search engine&apos;s and rank you higher. It helps
                        drive a huge amount of traffic to your website which can
                        convert to high-value sales.
                      </span>
                      <br></br>
                      <br></br>
                      <span>Some of the reasons to do SEO are as follows:</span>
                      <br></br>
                      <span className="digital-m-content-text05">➤  </span>
                      <span className="digital-m-content-text06">
                        Organic Search Is A Primary Source Of Website Traffic:
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: " ",
                          }}
                        />
                      </span>
                      <span>
                        Organic search results drive the majority of website
                        traffic, and studies show that over 50% of website
                        traffic comes from organic search. This means that if
                        your website is not optimized for search engines, you’re
                        missing out on a significant amount of potential
                        traffic.
                      </span>
                      <br></br>
                      <span className="digital-m-content-text10">➤  </span>
                      <span className="digital-m-content-text11">
                        Helps Build Trust And Credibility:
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: " ",
                          }}
                        />
                      </span>
                      <span>
                        People trust search engines, and if your website ranks
                        high in search results, it can help build trust and
                        credibility with your target audience. High search
                        engine rankings indicate that your website is a relevant
                        and authoritative source of information.
                      </span>
                      <br></br>
                      <span className="digital-m-content-text15">➤  </span>
                      <span className="digital-m-content-text16">
                        Improves User Experience And Engagement On Website:
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: " ",
                          }}
                        />
                      </span>
                      <span>
                        SEO involves optimizing your website’s structure and
                        content to make it easy for search engines to understand
                        and index your pages. This also improves the user
                        experience by making your website more organized,
                        informative, and user-friendly.
                      </span>
                      <br></br>
                    </p>
                  </Fragment>
                )}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

DigitalMContent.defaultProps = {
  content41: undefined,
  content22: undefined,
  rootClassName: "",
  heading21: "Social Media Marketing",
  heading4: "Search Engine Optimization",
  heading22131: "➤ Search Engine Optimization",
  heading22: "➤ Social Media Marketing",
  heading2: "Digital Marketing Services at DPS Tek include:",
  heading2214: "➤ Social Media Optimization",
  content11: undefined,
  content31: undefined,
  heading1: "Digital Marketing",
  heading3: "Social Media Optimization",
};

DigitalMContent.propTypes = {
  content41: PropTypes.element,
  content22: PropTypes.element,
  rootClassName: PropTypes.string,
  heading21: PropTypes.string,
  heading4: PropTypes.string,
  heading22131: PropTypes.string,
  heading22: PropTypes.string,
  heading2: PropTypes.string,
  heading2214: PropTypes.string,
  content11: PropTypes.element,
  content31: PropTypes.element,
  heading1: PropTypes.string,
  heading3: PropTypes.string,
};

export default DigitalMContent;
