import React, { useState, Fragment } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './pricing-digital-mkt.css'

const PricingDigitalMkt = (props) => {
  const [isMonthly, setIsMonthly] = useState(true)
  return (
    <div
      className={`pricing-digital-mkt-pricing23 thq-section-padding ${props.rootClassName} `}
    >
      <h3 className="pricing-digital-mkt-text thq-heading-3">
        Digital Marketing
      </h3>
      <div className="pricing-digital-mkt-max-width thq-section-max-width">
        <div className="pricing-digital-mkt-section-title"></div>
        <div className="pricing-digital-mkt-container">
          <div className="pricing-digital-mkt-column thq-card">
            <svg viewBox="0 0 1024 1024" className="pricing-digital-mkt-icon">
              <path d="M853.333 853.333h-682.667c-70.571 0-128-57.429-128-128v-426.667c0-70.571 57.429-128 128-128h682.667c70.571 0 128 57.429 128 128v426.667c0 70.571-57.429 128-128 128zM170.667 256c-23.509 0-42.667 19.157-42.667 42.667v426.667c0 23.509 19.157 42.667 42.667 42.667h682.667c23.509 0 42.667-19.157 42.667-42.667v-426.667c0-23.509-19.157-42.667-42.667-42.667h-682.667z"></path>
              <path d="M426.667 640h-170.667c-23.595 0-42.667-19.115-42.667-42.667s19.072-42.667 42.667-42.667h170.667c23.595 0 42.667 19.115 42.667 42.667s-19.072 42.667-42.667 42.667z"></path>
              <path d="M426.667 469.333h-170.667c-23.595 0-42.667-19.115-42.667-42.667s19.072-42.667 42.667-42.667h170.667c23.595 0 42.667 19.115 42.667 42.667s-19.072 42.667-42.667 42.667z"></path>
              <path d="M768 448c0 47.128-38.205 85.333-85.333 85.333s-85.333-38.205-85.333-85.333c0-47.128 38.205-85.333 85.333-85.333 47.128 0 85.333 38.205 85.333 85.333z"></path>
              <path d="M682.667 569.856c-66.645 0-106.667 30.507-106.667 60.971 0 15.232 40.021 30.507 106.667 30.507 62.549 0 106.667-15.232 106.667-30.507 0-30.464-41.813-60.971-106.667-60.971z"></path>
            </svg>
            <div className="pricing-digital-mkt-price">
              <div className="pricing-digital-mkt-price1">
                <p className="pricing-digital-mkt-text01 thq-body-large">
                  {props.plan1}
                </p>
                <h3 className="pricing-digital-mkt-text02 thq-heading-3">
                  {props.plan1Price}
                </h3>
              </div>
              <div className="pricing-digital-mkt-list">
                <div className="pricing-digital-mkt-list-item">
                  <span className="pricing-digital-mkt-text03 thq-body-small">
                    {props.plan1Feature1}
                  </span>
                </div>
                <div className="pricing-digital-mkt-list-item01">
                  <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="thq-body-small">{props.plan1Feature12}</span>
                </div>
                <div className="pricing-digital-mkt-list-item02">
                  <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="thq-body-small">{props.plan1Feature2}</span>
                </div>
                <div className="pricing-digital-mkt-list-item03">
                  <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="thq-body-small">{props.plan1Feature3}</span>
                </div>
                <div className="pricing-digital-mkt-list-item04">
                  <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="thq-body-small">{props.plan1Feature32}</span>
                </div>
              </div>
            </div>
            <Link
              to="/contact-us"
              className="pricing-digital-mkt-button thq-button-outline thq-button-animated"
            >
              <span className="pricing-digital-mkt-text08 thq-body-small">
                {props.plan1Action}
              </span>
            </Link>
          </div>
          <div className="pricing-digital-mkt-column1 thq-card">
            <svg viewBox="0 0 1024 1024" className="pricing-digital-mkt-icon14">
              <path d="M598 298v-84h-172v84h172zM854 298q34 0 59 26t25 60v128q0 34-25 60t-59 26h-256v-86h-172v86h-256q-36 0-60-25t-24-61v-128q0-34 25-60t59-26h170v-84l86-86h170l86 86v84h172zM426 682h172v-42h298v170q0 36-25 61t-61 25h-596q-36 0-61-25t-25-61v-170h298v42z"></path>
            </svg>
            <div className="pricing-digital-mkt-price2">
              <div className="pricing-digital-mkt-price3">
                <p className="pricing-digital-mkt-text09 thq-body-large">
                  {props.plan2}
                </p>
                <h3 className="pricing-digital-mkt-text10 thq-heading-3">
                  {props.plan2Price}
                </h3>
              </div>
              <div className="pricing-digital-mkt-list1">
                <div className="pricing-digital-mkt-list-item05">
                  <span className="pricing-digital-mkt-text11 thq-body-small">
                    {props.plan2Feature1}
                  </span>
                </div>
                <div className="pricing-digital-mkt-list-item06">
                  <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="thq-body-small">{props.plan2Feature2}</span>
                </div>
                <div className="pricing-digital-mkt-list-item07">
                  <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="thq-body-small">{props.plan2Feature3}</span>
                </div>
                <div className="pricing-digital-mkt-list-item08">
                  <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="thq-body-small">{props.plan2Feature4}</span>
                </div>
              </div>
              <div className="pricing-digital-mkt-list-item09">
                <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                  <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                </svg>
                <span className="thq-body-small">{props.plan2Feature43}</span>
              </div>
            </div>
            <Link
              to="/contact-us"
              className="pricing-digital-mkt-button1 thq-button-animated thq-button-filled"
            >
              <span className="pricing-digital-mkt-text16 thq-body-small">
                {props.plan2Action}
              </span>
            </Link>
          </div>
          <div className="pricing-digital-mkt-column2 thq-card">
            <svg viewBox="0 0 1024 1024" className="pricing-digital-mkt-icon24">
              <path d="M768 640v86h-86v-86h86zM768 470v84h-86v-84h86zM854 810v-426h-342v86h86v84h-86v86h86v86h-86v84h342zM426 298v-84h-84v84h84zM426 470v-86h-84v86h84zM426 640v-86h-84v86h84zM426 810v-84h-84v84h84zM256 298v-84h-86v84h86zM256 470v-86h-86v86h86zM256 640v-86h-86v86h86zM256 810v-84h-86v84h86zM512 298h426v598h-852v-768h426v170z"></path>
            </svg>
            <div className="pricing-digital-mkt-price4">
              <div className="pricing-digital-mkt-price5">
                <p className="pricing-digital-mkt-text17 thq-body-large">
                  {props.plan3}
                </p>
                <h3 className="pricing-digital-mkt-text18 thq-heading-3">
                  {props.plan3Price}
                </h3>
              </div>
              <div className="pricing-digital-mkt-list2">
                <div className="pricing-digital-mkt-list-item10">
                  <span className="pricing-digital-mkt-text19 thq-body-small">
                    {props.plan3Feature1}
                  </span>
                </div>
                <div className="pricing-digital-mkt-list-item11">
                  <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="thq-body-small">{props.plan3Feature2}</span>
                </div>
                <div className="pricing-digital-mkt-list-item12">
                  <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="thq-body-small">{props.plan3Feature3}</span>
                </div>
                <div className="pricing-digital-mkt-list-item13">
                  <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="thq-body-small">{props.plan3Feature4}</span>
                </div>
                <div className="pricing-digital-mkt-list-item14">
                  <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="thq-body-small">{props.plan3Feature5}</span>
                </div>
              </div>
            </div>
            <Link
              to="/contact-us"
              className="pricing-digital-mkt-button2 thq-button-animated thq-button-filled"
            >
              <span className="pricing-digital-mkt-text24 thq-body-small">
                {props.plan3Action}
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="pricing-digital-mkt-container1">
        <div className="pricing-digital-mkt-container2">
          <span>
            {props.text1 ?? (
              <Fragment>
                <span className="pricing-digital-mkt-text27">
                  Want to know about out various Google Ads,
                  Pay-Per-Click, Search Engine Optimization (On-page or
                  Off-page) to boost your business?
                </span>
              </Fragment>
            )}
          </span>
          <Link
            to="/contact-us"
            className="pricing-digital-mkt-button3 thq-button-animated thq-button-filled"
          >
            <span className="pricing-digital-mkt-text26 thq-body-small">
              {props.plan2Action2}
            </span>
          </Link>
        </div>
      </div>
    </div>
  )
}

PricingDigitalMkt.defaultProps = {
  plan1Feature32: '6 - Day Campaign',
  plan2Feature4: 'Banner Creation',
  plan3Price: '$199/mo',
  text1: undefined,
  plan2Feature2: 'Social Media Management',
  plan3Feature3: 'Lead Generation',
  plan2Feature3: 'Brand Awareness\n',
  plan3Feature4: 'In depth Analysis',
  plan1Feature1: 'Features include:',
  plan3Action: 'Get started',
  plan2Feature1: 'Foundation Plan, plus:',
  plan3Feature2: '1 Extra Social Media\n',
  plan1Feature12: 'Facebook Ads',
  plan2Action: 'Get started',
  plan2: 'Builder Plan',
  plan1Feature2: 'Instagram Ads',
  plan2Price: '$149/mo',
  plan3: 'Growth plan',
  plan2Feature43: '8 - Day Campaign',
  plan3Feature1: 'Builder Plan, plus:',
  plan1: 'Foundation Plan',
  plan3Feature5: '12 - Day Campaign',
  rootClassName: '',
  plan1Price: '$99/mo',
  plan1Action: 'Get Started',
  plan2Action2: 'Get started',
  plan1Feature3: 'Brand Promotion',
}

PricingDigitalMkt.propTypes = {
  plan1Feature32: PropTypes.string,
  plan2Feature4: PropTypes.string,
  plan3Price: PropTypes.string,
  text1: PropTypes.element,
  plan2Feature2: PropTypes.string,
  plan3Feature3: PropTypes.string,
  plan2Feature3: PropTypes.string,
  plan3Feature4: PropTypes.string,
  plan1Feature1: PropTypes.string,
  plan3Action: PropTypes.string,
  plan2Feature1: PropTypes.string,
  plan3Feature2: PropTypes.string,
  plan1Feature12: PropTypes.string,
  plan2Action: PropTypes.string,
  plan2: PropTypes.string,
  plan1Feature2: PropTypes.string,
  plan2Price: PropTypes.string,
  plan3: PropTypes.string,
  plan2Feature43: PropTypes.string,
  plan3Feature1: PropTypes.string,
  plan1: PropTypes.string,
  plan3Feature5: PropTypes.string,
  rootClassName: PropTypes.string,
  plan1Price: PropTypes.string,
  plan1Action: PropTypes.string,
  plan2Action2: PropTypes.string,
  plan1Feature3: PropTypes.string,
}

export default PricingDigitalMkt
