import React, { Fragment } from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import PricingHeader from "../components/pricing-header";
import PricingDigitalMkt from "../components/pricing-digital-mkt";
import PricingDevelopment from "../components/pricing-development";
import PricingContactCard from "../components/pricing-contact-card";
import Testimonial18 from "../components/testimonial18";
import PricingFAQ from "../components/pricing-faq";
import Footer from "../components/footer";
import "./pricing.css";

const Pricing = (props) => {
  return (
    <div className="pricing-container">
      <Helmet>
        <title>Pricing - DPS Tek</title>
        <meta property="og:title" content="Pricing - DPS Tek" />
      </Helmet>
      <Navbar></Navbar>
      <PricingHeader></PricingHeader>
      <PricingDigitalMkt
        text1={
          <Fragment>
            <span className="pricing-text">
              Want to know about out various Google Ads, Pay-Per-Click, Search
              Engine Optimization (On-page or Off-page) to boost your business?
            </span>
          </Fragment>
        }
        heading1="Pricing plans"
        rootClassName="pricing-digital-mkt-root-class-name"
      ></PricingDigitalMkt>
      <PricingDevelopment
        text={
          <Fragment>
            <h3 className="pricing-text1 thq-heading-3">
              <span>$399</span>
              <br></br>
            </h3>
          </Fragment>
        }
        text1={
          <Fragment>
            <span className="pricing-text4">
              Want to have your own custom plan, purchase domain or just host
              your existing website on our reliable, secure and fast servers?
            </span>
          </Fragment>
        }
        rootClassName="pricing-development-root-class-name"
      ></PricingDevelopment>
      <PricingContactCard
        text={
          <Fragment>
            <span className="pricing-text5 thq-heading-2">
              Looking for Cloud based solutions or other services??
            </span>
          </Fragment>
        }
        text1={
          <Fragment>
            <p className="pricing-text6 thq-body-large">
              Let our experts create a plan best suited for your needs and
              budget
            </p>
          </Fragment>
        }
        button={
          <Fragment>
            <span className="pricing-text7">Get a custom quote</span>
          </Fragment>
        }
      ></PricingContactCard>
      <Testimonial18 rootClassName="testimonial18-root-class-name"></Testimonial18>
      <PricingFAQ></PricingFAQ>
      <Footer rootClassName="footer-root-class-name7"></Footer>
    </div>
  );
};

export default Pricing;
