import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './about-intro.css'

const AboutIntro = (props) => {
  return (
    <div className="about-intro-about-intro">
      <span>
        {props.text ?? (
          <Fragment>
            <span className="about-intro-text1">
              <span>
                DPS Tek is a complete Cloud Services, Web Development &amp;
                Digital Marketing company that provide, various Cloud services
                in GCP, AWS, and Azure, SEO Services, PPC Services, and Web
                designing services which have contributed to many startups who
                are looking to capitalize on limitless possibilities that
                digital marketing can provide. We have established our brand
                value on the basis of our immaculate results, tangible ROI,
                professional approach to problems and tailor-made solutions for
                any clients.
              </span>
              <br></br>
              <br></br>
              <span>
                We are a team of highly experienced Senior Developers, Designers
                and Digital Marketers who provide solutions around the world. We
                have vast experience in the Service and Product industry. Worked
                for startups and made them achieve their defined goals. We
                always look to save a large chunk of ad budget and thrive to
                achieve top ad placement and higher CTR. We have cemented our
                place in digital advertising and search engine marketing
                industry by earning the tag of a trustworthy digital company
                providing result-driven advertising and digital marketing
                solution. We are also actively serving international clientele
                with our wide market presence.
              </span>
            </span>
          </Fragment>
        )}
      </span>
    </div>
  )
}

AboutIntro.defaultProps = {
  text: undefined,
}

AboutIntro.propTypes = {
  text: PropTypes.element,
}

export default AboutIntro
